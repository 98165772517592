import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="ui main text container contactbody">
            <h3>Email</h3>
            <a href='mailto:sonney.patel@gmail.com'>sonney.patel@gmail.com</a>
        </div>
    );
}

export default Contact;