import React from 'react';
import {Route, Switch, Router} from 'react-router-dom';
import Header from "./Header";
import TypewriterBody from "./TypewriterBody";
import Contact from './Contact';
import About from './About';

import history from "../history";


const App = () => {
    return (
        <div className="ui vertical masthead center aligned segment">
            <Router history={history}>
                <div>
                    <Header/>
                    <Switch>
                        <Route path='/' exact component={TypewriterBody}/>
                        <Route path='/about' exact component={About}/>
                        <Route path='/contact' exact component={Contact}/>
                    </Switch>
                </div>
            </Router>
        </div>
    );
}

export default App;