import React, {useState} from 'react';
import {Link} from 'react-router-dom';

const Header = () => {

    const [active, setActive] = useState();

    const onClick = (event) => {
        setActive(event.target.innerText);
    }

    const isActive = (value) => value === active ? 'active' : '';

    return (
        <div className="ui large secondary pointing menu">
            <div className="ui container">
                <div className="left item">
                    <Link className={`header item ${isActive("Home")}`} to="/" onClick={e => onClick(e)}>
                        Home
                    </Link>
                </div>
                <div className="right item">
                    <a className={`header item ${isActive("GitHub")}`} href="https://github.com/sonneybouy" onClick={e => onClick(e)}>
                        <i className="github icon" />
                        GitHub
                    </a>
                    <Link className={`header item ${isActive("About Me")}`} to='/about' onClick={e => onClick(e)}>
                        About Me
                    </Link>
                    <Link className={`header item ${isActive("Contact")}`} to="/contact" onClick={e => onClick(e)}>
                        Contact
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Header;
