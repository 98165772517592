import React from 'react';
import './About.css';

const About = () => {
    return (
        <div className="ui main text container aboutbody">
            <section id="welcome">
                <h2>Welcome to My Professional Space!</h2>
                <p>I am Sonney Patel, a seasoned software developer with a passion for crafting robust and scalable
                    digital solutions. With years of hands-on experience in the tech industry, I've honed my skills in
                    various programming languages and frameworks to deliver exceptional results for my clients.</p>
            </section>

            <section id="about-me">
                <h2>Who I Am</h2>
                <p>I've been in the trenches of code and development, where I've built comprehensive web platforms, scaled architecture, wrestled bugs and drawn designs.
                    My journey through the evolving landscape of technology has
                    equipped me with not just technical expertise but also an adaptive approach to problem-solving and great communication skills.</p>
            </section>

            <section id="services">
                <h2>What I Do</h2>
                <div className="service">
                    <h3>As a Software Developer:</h3>
                    <ul>
                        <li>I specialize in creating high-performance applications using cutting-edge technologies.</li>
                        <li>I have a proven track record of successful projects, showcasing my ability to navigate the
                            full software development lifecycle with finesse.
                        </li>
                        <li>My portfolio includes work with startups, mid-size companies, and large enterprises,
                            delivering products that stand the test of time and scale.
                        </li>
                    </ul>
                </div>
                <div className="service">
                    <h3>As a Consultant:</h3>
                    <ul>
                        <li>I offer consulting services that help businesses leverage technology for growth and
                            efficiency.
                        </li>
                        <li>I work closely with clients to understand their challenges and provide strategic solutions
                            tailored to their unique needs.
                        </li>
                        <li>My consulting extends beyond code — I focus on streamlining processes, reducing operational
                            overhead, and enhancing the user experience.
                        </li>
                    </ul>
                </div>
            </section>

            <section id="contact">
                <h2>Let's Connect</h2>
                <p>Are you ready to transform your ideas into digital reality? Let's embark on this journey together.
                    For consultations, project discussions, or just a friendly chat about the future of technology, reach out to me.</p>
                <p>I look forward to collaborating with you and pushing the boundaries of what's possible.</p>
            </section>
        </div>
    );
}

export default About;