import React from 'react';
import Typewriter from 'typewriter-effect';
import './TypewriterBody.css';

const TypewriterBody = () => {
    return (
        <div className="ui text container typewriterbody">
            <h1 className="ui huge header">
                <Typewriter
                    onInit={(typewriter => typewriter.typeString("Sonney Patel").start())}
                />
            </h1>
            <h2 className="ui header">
                <Typewriter
                    onInit={(typewriter => typewriter.typeString("Trading as Sonney LTD").start())}
                />
            </h2>
        </div>
    );
}

export default TypewriterBody;